import React, { useState, FC, useEffect } from 'react';
import { MessagePlugin } from 'tdesign-react';
import { SelectTopic } from 'components/select-topic';
import { deleteApiV2PostTopics, postApiV2PostTopics } from 'components/api';
import batchTopic from 'components/api/batch-topic';
import { hrefTopicDetail } from 'components/href-helper';
import { Collect } from 'components/report';
/**
 * 容器组件帖子的话题选择。
 */
interface SelectTopicContainerProps {
  productId: string | number;
  postId: string | number;
}

interface Topic {
  id: number;
  title: string;
}
const SelectTopicContainer: FC<SelectTopicContainerProps> = ({ productId, postId }) => {
  const [defaultTopic, setDefaultTopic] = useState(null);
  // 更新初始默认话题
  useEffect(() => {
    setDefaultTopic(null);
    batchTopic.getTopic({ productId, postId, isProduct: true })
      .then((topics) => {
        if (!topics) {
          setDefaultTopic(topics);
        } else {
          setDefaultTopic(topics[0]);
        }
      });
  }, [postId, productId]);

  const handleTopicCancel = (topic: Topic) => {
    deleteApiV2PostTopics({ productId, postId, topicId: topic.id });
  };

  const handleTopicChange = (topic: Topic, isCreat) => {
    postApiV2PostTopics({ productId, postId, topicId: topic.id })
      .then(() => {
        new Collect({
          ec: '功能投票-PC端',
          ea: 'CLICK',
          el: isCreat ? '关联投票项-创建投票主题' : '关联到功能投票',
        }).report();
        const text = isCreat ? '创建并添加投票话题成功' : '添加话题投票成功';
        MessagePlugin.success(text);
      });
  };

  const handleTopicClick = (topic: Topic) => {
    hrefTopicDetail(productId, topic.id);
  };



  if (defaultTopic === null) return null;
  return (
    <SelectTopic
      productId={productId}
      defaultTopic={defaultTopic}
      onCancel={handleTopicCancel}
      onChange={handleTopicChange}
      onItemClick={handleTopicClick}
      isAdmin
    />
  );
};

export default SelectTopicContainer;
