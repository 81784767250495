/* eslint-disable complexity, camelcase, react/prop-types, jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import classNames from 'classnames';
import { ImageAlbum } from '@tencent/coral-design';
import { Tooltip } from 'tdesign-react';
import { ceGroupName, href_profile } from 'components/href-helper';
import { dangerouslySetInnerHTML } from 'components/render-rich-text';
import { Avatar } from 'components/user';
import { POST_TYPE } from 'components/constants/app';
import SupportBtn, { TYPE as SUPPORT_BTN_TYPE } from 'components/ui/common/support-btn';
import 'components/skeleton';
import { BtnsStyle, BtnLike, BtnReward, BtnShare, BtnMoreWithDropdown, BtnPlatforms } from 'components/btn-icon';
import { Menu, ConfirmDelete } from 'components/comment-item';
import { PostLabel } from 'components/post-label';
import { ImageDesktop } from 'components/comment-image/image-desktop';
import { TcIconShut } from 'components/IconSystem';
import { DescPara } from 'components/post-item/desc-para.desktop';
// import { Guide } from 'components/guide';
import { postsTime, getIWantContent, getChangeLogContent } from 'components/util';
import { BtnTapd } from 'components/btn-icon/btn-tapd';
import { Collect } from 'components/report';
import { PLATEFORM_ENUM } from 'components/constants/tapd';
import { TagShow } from 'components/tag-show';
import Topic from 'components/topic/topic-container';
import './style.less';
import SelectTopic from 'components/select-topic/select-topic-container';
import { ChangeLog } from 'components/change-log-pc';
import { bloomFilterInsert } from 'components/change-log-pc/utils/utils';

class FeedDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm_delete: false,

      // 默认为 null，当有值的时候，表示显示某个图片
      show_image_album: null,
    };

    this.onClickMenu = this.onClickMenu.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.dropdownRef = React.createRef();
  }

  onClickMenu({ opt, val }) {
    const { props } = this;

    if (opt === 'delete') {
      this.setState({ confirm_delete: true });
      return;
    }
    this.setState({ showMenu: false });
    props.onClickMenu({ opt, val });
  }

  confirmDelete() {
    const { props } = this;
    this.setState({ showMenu: false, confirm_delete: false });
    props.onClickDelete();
  }

  cancelDelete() {
    this.setState({ confirm_delete: false });
    this.dropdownRef.current.close();
  }

  isOwner() {
    const { props } = this;

    if (!props.user) {
      return false;
    }

    return Boolean(Number(props.user.user_id) === Number(props.user_id));
  }

  getLastCategories() {
    const { props } = this;

    if (props.categories.length === 0) {
      return false;
    }

    return props.categories[props.categories.length - 1];
  }

  handleImagesClick(index) {
    this.setState({ show_image_album: index });
  }

  handleMattersClick(item) {
    if (!item || !item.url || item.isBad) return;
    new Collect({
      ea: '反馈详情-相关工单',
      ev: PLATEFORM_ENUM[item.platform_id],
    }).report();
    window.open(item.url);
  }

  onBtnMoreClick() {
    if (this.props.onCheckLogin()) {
      this.setState({ showMenu: true });
    }
  }

  getLikeList() {
    const { like_list: likeList } = this.props;
    if (!likeList) {
      return '';
    }
    let str = likeList.join('、');
    if (str.length > 45) {
      str = `${str.slice(0, 45)}...`;
    }
    return str;
  }

  renderWant() {
    const { like_count: likeCount, content: postContent, like_list: likeList, images } = this.props;
    const { title, content, solution } = getIWantContent(postContent);
    return (
      <div className="user_desc user_desc--want">
        {!!likeCount && likeList && likeList.length !== 0 && (
          <div className="user_desc__like-list">
            <span>{this.getLikeList()}</span>
            <span>{likeCount > 1 ? `等${likeCount}人` : ''}所见略同</span>
          </div>
        )}
        <div className="user_desc__title">我想要</div>
        <div className="user_desc__title-main">
          <DescPara desc={title} renderLink={this.props.renderLink === 'on' || this.props.is_admin} />
        </div>
        <div className="user_desc__content">
          <span className="user_desc__emoji" aria-hidden>
            😤{' '}
          </span>
          目前痛点
        </div>
        <div className="user_desc__content-main">
          <DescPara desc={content} renderLink={this.props.renderLink === 'on' || this.props.is_admin} />
        </div>
        <div className="user_desc__solution">
          <span className="user_desc__emoji" aria-hidden>
            💡{' '}
          </span>
          我的想法
        </div>
        <div className="user_desc__solution-main">
          <DescPara desc={solution} renderLink={this.props.renderLink === 'on' || this.props.is_admin} />
        </div>
        {images.length > 0 && <ImageDesktop imgList={images} handleImagesClick={this.handleImagesClick.bind(this)} />}
      </div>
    );
  }

  renderDetail(props) {
    if (props.type === POST_TYPE.I_WANT) {
      return this.renderWant();
    }

    if (props.type === POST_TYPE.CHANGE_LOG) {
      const { content: postContent } = this.props;
      // bloomFilterInsert(this.props.id);
      const changeLog = getChangeLogContent(postContent);
      changeLog.date = this.props.created_at_timestamp;
      changeLog.id = this.props.id;
      return <ChangeLog changeLog={changeLog} style={{ marginTop: '8px' }} productId={this.props.productId} />;
    }

    return (
      <div className="user_desc">
        <div
          className="feed_desc_font skeleton-multi"
          dangerouslySetInnerHTML={dangerouslySetInnerHTML(props.content, props.productId, 'p', props.renderLink === 'on' || props.is_admin)}
        />
        {props.images.length > 0 && (
          <ImageDesktop imgList={props.images} handleImagesClick={this.handleImagesClick.bind(this)} />
        )}
      </div>
    );
  }

  // 返回显示标签
  getTag(isAdmin, isAuthorId) {
    if (isAdmin) {
      return <span className="user_infor__admin">官方</span>;
    }
    if (isAuthorId) {
      return <span className="user_infor__author">作者</span>;
    }
    return null;
  }

  renderImage() {
    const { state, props } = this;
    return (
      state.show_image_album !== null && (
        <ImageAlbum
          imageList={props.images.map(img => img.original_url)}
          previewList={props.images.map(img => img.thumb_url)}
          showPreview={true}
          showIndex={state.show_image_album}
          onClose={() => this.setState({ show_image_album: null })}
        />
      )
    );
  }

  /**
   * 话题渲染
   * 普通用户根据产品是否开启了
   */
  renderTopic = () => {
    const { isAdmin, id, showTopic, productId } = this.props;
    if (!id || !showTopic) return null;
    if (isAdmin) {
      return <SelectTopic productId={productId} postId={id} />;
    }

    return (
      <div className="topic_title">
        <Topic
          productId={productId}
          postId={id}
          isProduct
        />
      </div>
    );
  }

  render() {
    const { state, props } = this;
    const tip_total = (props.tip_batch.reduce((acc, item) => acc + item.amount, 0) / 100).toFixed(2);
    const like_count = (props.liked_list[props.id] && props.liked_list[props.id].like_count) || props.like_count;
    const likeFlagLiked = props.liked_list[props.id];
    const like_flag = likeFlagLiked && (props.liked_list[props.id].liked || props.liked_list[props.id].like_flag);
    const isOwner = this.isOwner();
    const last_categories = this.getLastCategories();
    const showRewardInfo = props.tip_batch.length > 0 && props.canReward === 'on';
    const canReward = props.can_be_tipped && props.canReward === 'on';
    const isRight = !!(canReward && props.tapdEntry);
    const isWant = props.type === POST_TYPE.I_WANT;

    return (
      <div className="feed_top">
        <div className="feed_top_user">
          <div className="feed_top_user-wrap">
            <a
              href={`/products/${props.productId}/profile/${props.user_id}`}
              target="_blank"
              className="feed__avatar-wrap"
              rel="noreferrer"
            >
              <Avatar className="user_pic" src={props.avatar_url} />
            </a>
            {isWant && (
              <SupportBtn
                isLiked={like_flag}
                count={like_count || 0}
                onClick={props.onClickLike}
                type={SUPPORT_BTN_TYPE.PC}
              />
            )}
          </div>
          <div className="user_right">
            <div className="user_infor">
              <h5
                className={classNames('user_infor_name', {
                  is_admin: props.is_admin,
                })}
              >
                <a
                  href={href_profile(props.user_id)}
                  className="skeleton-block"
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.nick_name}
                </a>
              </h5>

              {this.getTag(props.is_admin, props.user_id === props.user.user_id)}

              {(props.group_name || props.postname) && (
                <div className="user_infor_group_name">{ceGroupName(props.group_name, props.postname)}</div>
              )}

              <div className="user_infor_font skeleton-inline">
                {props.location} {postsTime(props.created_at_timestamp)}
              </div>
              <div className="user_infor_label">
                <PostLabel
                  is_top={props.is_top}
                  is_hidden={props.is_hidden}
                  is_spam={props.is_spam}
                  is_good={props.is_good}
                />
              </div>
              <div className="user_infor_tag">
                <TagShow tags={props.tags} />
              </div>
            </div>
            {this.renderTopic()}
            {this.renderDetail(props)}
            <div className="user_other">
              {showRewardInfo && (
                <div className="user_apprec">
                  {props.tip_batch.map((item, index) => (
                    <Avatar className="user_pic" src={item.avatar} key={index} />
                  ))}
                  <span className="apprec_times">
                    {props.tip_batch.length}人赞赏 共{tip_total}元
                  </span>
                </div>
              )}

              {/* 关联平台的绑定事项 */}
              <ul
                className={classNames('coding-list--ul', {
                  'skeleton-block': props.mattersLoading,
                })}
              >
                {props.authMatters.map(item => (
                  <li
                    className={classNames('coding-list--li', {
                      'coding-list--li-bad': item.isBad,
                    })}
                    key={item.id}
                  >
                    <div
                      tabIndex="-1"
                      role="button"
                      className="coding-list--left"
                      onClick={this.handleMattersClick.bind(this, item)}
                    >
                      <div className="coding-list--project-logo">
                        {item.platform_logo && <img src={item.platform_logo} alt="platform_logo_img" />}
                      </div>
                      <Tooltip content={<span className="coding-list__p--tooltip">{item.title}</span>}>
                        <p className="coding-list__p--info">
                          <span className="coding-list__span--status">【{item.status}】</span>
                          <span>{item.title}</span>
                        </p>
                      </Tooltip>
                    </div>
                    <div className="coding-list--right">
                      <span className="coding-list__span--person">{item.user}</span>
                      <i
                        tabIndex="-1"
                        role="button"
                        className="coding-list__i--delete"
                        onClick={() => props.onMattersDelete(item)}
                      >
                        <TcIconShut />
                      </i>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="feed_top_func">
          <div className="external">{last_categories && <span>{`#${last_categories.value}#`}</span>}</div>

          <BtnsStyle isMobile={false}>
            <BtnMoreWithDropdown
              ref={this.dropdownRef}
              onClick={() => this.onBtnMoreClick()}
              hasParentEle={props.hasParentEle}
            >
              <div style={{ display: state.confirm_delete ? 'block' : 'none' }}>
                <ConfirmDelete onConfirm={() => this.confirmDelete()} onCancel={() => this.cancelDelete()} />
              </div>

              <div style={{ display: !state.confirm_delete ? 'block' : 'none' }}>
                {state.showMenu && (
                  <Menu
                    id={props.id}
                    is_Right={isRight}
                    productId={props.productId}
                    has_admin_reply={props.has_admin_reply}
                    is_abuse={props.is_abuse}
                    is_reply={false}
                    is_top={props.is_top}
                    is_locked={props.is_locked}
                    is_hidden={props.is_hidden}
                    is_spam={props.is_spam}
                    is_good={props.is_good}
                    is_admin={props.user.is_admin}
                    is_owner={isOwner}
                    is_admin_post={props.is_admin}
                    onClick={this.onClickMenu}
                    tagArrList={props.tagArrList}
                    changePostsList={props.changePostsList}
                  />
                )}
              </div>
            </BtnMoreWithDropdown>

            {canReward && <BtnReward onClick={props.onClickTipped} />}

            <BtnShare href={props.shareHref} />

            {/* tapd为内部项目始终展示的按钮，不使用platformsList中的数据来渲染 */}
            {props.tapdEntry && <BtnTapd onClick={props.onClickTapd} />}

            {!isWant && <BtnLike onClick={props.onClickLike} isLiked={like_flag} like_count={like_count} />}

            {props.platformsList.map((item) => {
              if (item.app_count === 0) return null;
              // tapd为始终展示的按钮，不使用platformsList中的数据来渲染
              if (props.tapdEntry && item.platform_name.toLowerCase() === 'tapd') {
                return null;
              }

              return (
                <React.Fragment key={item.id}>
                  <BtnPlatforms
                    name={item.platform_name}
                    logo={item.logo}
                    onClick={() => props.onClickPlatform(item)}
                  />
                </React.Fragment>
              );
            })}
          </BtnsStyle>
        </div>

        {this.renderImage()}
      </div>
    );
  }
}

FeedDetail.defaultProps = {
  nick_name: '',
  content: '',
  images: [],
  location: '',
  time: '',
  avatar_url: null,
  is_top: false,
  is_hidden: false,
  is_spam: false,
  tip_batch: [],
  id: '',
  can_be_tipped: false,
  user: {},
  liked_list: {},
  categories: [],
  shareHref: location.href,
  tapdEntry: false,
  authMatters: [],
  mattersLoading: false,
  platformsList: [],
  onClickLike() {
    console.log('onClickLike 未绑定');
  },
  onClickMenu(res) {
    console.log(`onClickMenu 未绑定, ${res}`);
  },
  onClickTipped() {
    console.log('onClickTipped 未绑定');
  },
  onClickPlatform() {
    console.log('onClickPlatform 未绑定');
  },
  onMattersDelete() {
    console.log('onMattersDelete 未绑定');
  },
  onCheckLogin: () => false,
  changePostsList() {
    console.log('changePostsList 未绑定');
  },
};

// export const href_profile = (user_id, productId) => {
//   if (!productId || !user_id) return;
//   return `https://support.qq.com/products/${productId}/profile/${user_id}/`;
// };

export { FeedDetail };
